import { Box, Typography } from '@material-ui/core';
import { ActivityIndicator } from 'components/ActivityIndicator';
import React from 'react';
import { AnimatedCheck } from './AnimatedCheck';
import { JourneyLink } from './JourneyLink';
import { Payment } from './Payment';
import { PercentageCompleteDisplay } from './PercentageCompleteDisplay';
import { StarRating } from './StarRating';
import { IJourneyState } from './utils';

interface IProps {
  journeyState: IJourneyState;
}

export function Assessment(props: IProps) {
  const { journeyState } = props;
  const {
    disableAssessment,
    isLoading,
    isPaymentRequired,
    assessmentStarted,
    isCritical,
    starRating,
    percentageComplete,
    isRenewal,
    assessmentSubmitted,
  } = journeyState;

  const isComplete = !isPaymentRequired && !isCritical && percentageComplete === 100;

  if (isLoading) {
    return (
      <div className="mdl-spinner">
        <ActivityIndicator />
      </div>
    );
  }

  return (
    <>
      <div className={isComplete ? 'success' : 'pending'}>
        <i className="icon">{isComplete ? <AnimatedCheck /> : <i>3</i>}</i>
        <div className="details">
          <h4>Licensing SAVI Assessment</h4>
        </div>

        {isPaymentRequired ? (
          <div>
            <Payment isRenewal={isRenewal} disabled={disableAssessment} />
          </div>
        ) : (
          <>
            {!assessmentStarted ? (
              <div>
                <p>Start your assessment below.</p>
                <JourneyLink to="/assessment?new" disabled={disableAssessment}>
                  Begin Assessment
                </JourneyLink>
              </div>
            ) : (
              <div>
                {isCritical && (
                  <div>
                    <div className="alert alert--error">
                      <h4>Warning</h4>
                      <p>
                        Your response to one or more of the questions has indicated that a practice
                        within your business may be unlawful. You will not see an overall star
                        rating, or advice and recommendations for all your other questions answered,
                        until this practice has been addressed.
                      </p>
                      <p>
                        You can see exactly which question(s) have been highlighted by clicking on
                        the <strong>advice and recommendations</strong> below.
                      </p>
                    </div>
                    <JourneyLink to="/recommendations">Advice and recommendations</JourneyLink>
                  </div>
                )}

                {percentageComplete !== 100 && (
                  <div>
                    <p>Your current assessment progress:</p>

                    <PercentageCompleteDisplay value={percentageComplete} />
                    <div>
                      <JourneyLink to="/assessment" disabled={disableAssessment}>
                        Continue Assessment
                      </JourneyLink>
                    </div>
                  </div>
                )}

                {!isCritical && percentageComplete === 100 && (
                  <div>
                    <p>
                      Your current Licensing SAVI assessment rating is shown below. Please{' '}
                      <strong>view your recommendations</strong> at step 4 to improve the rating if
                      required, or <strong>submit for accreditation</strong> at step 5.
                    </p>
                    <StarRating value={starRating} />
                    {isRenewal && !assessmentSubmitted && (
                      <div className="alert alert--error">
                        <Box my={1}>
                          <h5>You're not done yet!</h5>
                          <Typography style={{ marginBottom: 0 }}>
                            Don't forget to re-apply for accreditation at step 5.
                          </Typography>
                        </Box>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        <div className="foot"></div>
      </div>
    </>
  );
}

