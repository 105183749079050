import { ReviewStatus } from 'hooks/useGetReviewsData';
import React from 'react';
import { formatReviewDate } from 'utils';
import { AnimatedCheck } from './AnimatedCheck';
import { IJourneyState } from './utils';

function getTitle(reviewStatus: ReviewStatus | '' | undefined): string {
  switch (reviewStatus) {
    case 'REJECTED':
      return 'Rejected';
    case 'APPROVED':
      return 'Congratulations!!';
    default:
      return 'Nearly there...';
  }
}

interface IProps {
  journeyState: IJourneyState;
}

export function Complete(props: IProps) {
  const { journeyState } = props;
  const { reviewStatus, expiryDate } = journeyState;

  const isComplete = reviewStatus === 'APPROVED';

  return (
    <>
      <div className={isComplete ? 'success' : 'pending'}>
        <i className="icon">{isComplete ? <AnimatedCheck /> : <i>6</i>}</i>
        <div className="details">
          <h4>{getTitle(reviewStatus)}</h4>
        </div>
        {(!reviewStatus || reviewStatus === 'PEND') && (
          <p>
            Don't forget to apply for accreditation – even if you are renewing a previous
            application.
          </p>
        )}
        {reviewStatus === 'REJECTED' && (
          <p>
            Your application has been <b>REJECTED</b>.
          </p>
        )}
        {reviewStatus === 'PENDING' && (
          <>
            <p>
              Your Licensing SAVI assessment has now been submitted for verification. Look out for
              e-mail updates.
            </p>
            <p>Once approved, your certificate and stickers will be sent to you.</p>
          </>
        )}
        {reviewStatus === 'APPROVED' && (
          <>
            <p>Your Licensing SAVI assessment has now been approved. </p>
            <p>
              Your certificate and stickers will be sent to you. Please display these with pride and
              make sure to celebrate your success with all your staff & customers.
            </p>
            <p>
              Your accreditation is now live
              {expiryDate && (
                <>
                  {' '}
                  and will be due for renewal on <b>{formatReviewDate(expiryDate)}</b>
                </>
              )}
              .
            </p>
          </>
        )}

        <div className="foot"></div>
      </div>
    </>
  );
}

