import { ApolloError } from '@apollo/client';
import { useGetSiteAndChildren } from 'apollo-hooks';
import { useParams } from 'react-router';
import { SiteItemFragment } from 'tillr-graphql';

interface IState {
  chainId: number;
  venueId: number | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  chainSite: SiteItemFragment | undefined;
  chainSiteAndChildren: Array<SiteItemFragment> | undefined | null;
  venueSite: SiteItemFragment | undefined;
}

export function useGetChainFromParams(): IState {
  const { chainId: chainIdString, venueId: venueIdString } = useParams<{
    chainId: string;
    venueId: string | undefined;
  }>();

  const chainId = Number(chainIdString);
  const venueId = venueIdString ? Number(venueIdString) : undefined;

  const { loading, error, data } = useGetSiteAndChildren({ id: chainId });

  const chainSiteAndChildren = data?.siteAndChildren;
  const chainSite = chainSiteAndChildren?.find((site) => site.id === chainId);
  const venueSite = venueId ? chainSiteAndChildren?.find((site) => site.id === venueId) : undefined;

  return {
    chainId,
    venueId,
    loading,
    error,
    chainSite,
    chainSiteAndChildren,
    venueSite,
  };
}

