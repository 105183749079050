import { ExternalLink } from 'components/Shared/ExternalLink';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  isAdminView?: boolean;
}

export function ResourcesPanel(props: IProps) {
  const { isAdminView } = props;

  return (
    <ul>
      {isAdminView && (
        <>
          <li>
            <Link to="/resources/welcome-letter">
              <i className="glyphicon glyphicon-file"></i> Welcome letter and important information
            </Link>
          </li>
          <li>
            <Link to="/resources/end-user-agreement">
              <i className="glyphicon glyphicon-file"></i> End User Agreement
            </Link>
          </li>
        </>
      )}
      <li>
        <Link to="/resources/counter-terrorism">
          <i className="glyphicon glyphicon-file"></i> Counter Terrorism security advice for
          Licensed Premises
        </Link>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/Spiking advice to Venues and Customers (Spiking Policy).docx">
          <i className="filetypes filetypes-pdf"></i> Spiking Advice To Venues And Customers
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/TemplateAgeVerificationPolicy.docx">
          <i className="filetypes filetypes-docx"></i> Template Age Verification Policy
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/Template Child Exploitation Policy.docx">
          <i className="filetypes filetypes-docx"></i> Template Child Exploitation Policy
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/TemplateDispersalPolicy.docx">
          <i className="filetypes filetypes-docx"></i> Template Dispersal Policy
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/TemplateDrugsPolicy.docx">
          <i className="filetypes filetypes-docx"></i> Template Drugs Policy
        </ExternalLink>
      </li>
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/PremisesFemaleSafetyActionPlanTemplate.docx">
          <i className="filetypes filetypes-docx"></i> Template Female Safety (VAWG) Policy
        </ExternalLink>
      </li>
      {!isAdminView && (
        <>
          <li>
            <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/TemplateRefusalsRegister.docx">
              <i className="filetypes filetypes-docx"></i> Template Premises Refusals Register
            </ExternalLink>
          </li>
          <li>
            <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/TemplateIncidentLog.docx">
              <i className="filetypes filetypes-docx"></i> Template Premises Incident Log
            </ExternalLink>
          </li>
        </>
      )}
      <li>
        <ExternalLink href="https://tillrfiles.blob.core.windows.net/static/lsavi-resources/CrimeScenePreservationGuide.docx">
          <i className="filetypes filetypes-docx"></i> Crime Scene Preservation Guide
        </ExternalLink>
      </li>
    </ul>
  );
}

