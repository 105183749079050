import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router';
import UserManagerContext from 'UserManagerContext';
import UserProfileContext from 'UserProfileContext';
import { AuthScreen } from './AuthScreen';

export function LogIn() {
  const userManager = useContext(UserManagerContext)!;
  const userProfile = useContext(UserProfileContext);

  useEffect(() => {
    if (!userProfile) {
      userManager.signinRedirect();
    }
  }, [userProfile, userManager]);

  if (userProfile) {
    return <Redirect to="/" />;
  }

  return <AuthScreen state="redirecting" />;
}
