import { isAfter } from 'date-fns';
import { ReviewStatus } from 'hooks/useGetReviewsData';
import { IVenueStatusData } from 'hooks/useGetVenueStatuses';
import { UserSettings } from 'types';

export interface IJourneyState {
  isLoading: boolean;
  title: string;
  isPremisesInfoComplete: boolean;
  isEndUserAgreementComplete: boolean;
  isPaymentRequired: Boolean;
  isRenewal: boolean;
  assessmentFormId: string | undefined;
  assessmentStarted: boolean;
  percentageComplete: number;
  isCritical: boolean;
  starRating: number;
  assessmentSubmitted: boolean;
  reviewStatus: ReviewStatus | '' | undefined;
  expiryDate: Date | null | undefined;
  disableAssessment: boolean;
  disableAdvice: boolean;
  disableAccreditation: boolean;
  disableComplete: boolean;
}

export function getJourneyState(
  statusHistory: Array<IVenueStatusData> | undefined,
  userSettings: UserSettings | undefined,
  todaysDate = new Date(),
): IJourneyState {
  const journeyState: IJourneyState = {
    isLoading: true,
    title: 'Your accreditation journey',
    isPremisesInfoComplete: false,
    isEndUserAgreementComplete: false,
    isPaymentRequired: true,
    isRenewal: false,
    assessmentFormId: undefined,
    assessmentStarted: false,
    percentageComplete: 0,
    isCritical: false,
    starRating: 0,
    assessmentSubmitted: false,
    reviewStatus: undefined,
    expiryDate: undefined,
    disableAssessment: true,
    disableAdvice: true,
    disableAccreditation: true,
    disableComplete: true,
  };

  if (statusHistory === undefined || userSettings === undefined) {
    return journeyState;
  }

  journeyState.isLoading = false;

  // Most recent status is last
  const status = statusHistory[statusHistory.length - 1];
  if (!status) {
    return journeyState;
  }

  journeyState.title = `${status.SiteName} – accreditation journey`;

  journeyState.isPremisesInfoComplete = Boolean(status.PremisesInfoFormId);
  journeyState.isEndUserAgreementComplete = Boolean(status.EndUserAgreementFormId);

  journeyState.disableAssessment =
    !journeyState.isPremisesInfoComplete || !journeyState.isEndUserAgreementComplete;

  const canRenewNow = Boolean(
    status.CanRenewDateTime && isAfter(todaysDate, status.CanRenewDateTime),
  );

  // If we can renew, payment received must be after renewal date
  const isPaymentReceived = canRenewNow
    ? Boolean(status.PaymentDateTime && isAfter(status.PaymentDateTime, status.CanRenewDateTime!))
    : Boolean(status.PaymentDateTime);

  const isAssessmentFree = Boolean(userSettings?.origin || userSettings?.paymentExempt);

  journeyState.isPaymentRequired = !isPaymentReceived && !isAssessmentFree;

  if (canRenewNow) {
    // We want to start a new assessment, so don't load any more info from the previous record
    journeyState.isRenewal = true;
    return journeyState;
  }

  journeyState.isRenewal = statusHistory.length > 1;

  journeyState.assessmentFormId = status.AssessmentFormId;
  journeyState.assessmentStarted = Boolean(status.AssessmentFormId);

  journeyState.percentageComplete = status.PercentageComplete;
  journeyState.isCritical = status.IsCritical;
  journeyState.starRating = status.StarRating ?? 0;

  journeyState.disableAdvice =
    journeyState.disableAssessment ||
    !journeyState.assessmentStarted ||
    journeyState.percentageComplete < 100;

  journeyState.disableAccreditation = journeyState.disableAdvice || journeyState.isCritical;

  journeyState.assessmentSubmitted = Boolean(status.AssessmentSubmittedDateTime);
  journeyState.reviewStatus = status.CurrentReviewStatus;
  journeyState.expiryDate = status.AccreditationExpiryDateTime;

  journeyState.disableComplete = !journeyState.assessmentSubmitted;

  return journeyState;
}

