import { ActivityIndicator } from 'components/ActivityIndicator';
import { useGetReviewsData } from 'hooks/useGetReviewsData';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatReviewDate } from 'utils';
import { AnimatedCheck } from './AnimatedCheck';
import { JourneyLink } from './JourneyLink';
import { IJourneyState } from './utils';

interface IProps {
  journeyState: IJourneyState;
}

export function Accreditation(props: IProps) {
  const { journeyState } = props;
  const { disableAccreditation, assessmentFormId, isCritical, percentageComplete } = journeyState;

  const reviewsQuery = useGetReviewsData(assessmentFormId);

  const { isLoading, reviewStatus, requestedDate, expiryDate, data } = reviewsQuery;
  const pendInfo = data?.filter((status) => status.Status === 'Paused');

  const isComplete = reviewStatus === 'APPROVED';

  return (
    <>
      <div className={isComplete ? 'success' : 'pending'}>
        <i className="icon">{isComplete ? <AnimatedCheck /> : <i>5</i>}</i>
        <div className="details">
          <h4>Application for accreditation</h4>
        </div>

        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            {!reviewStatus ? (
              <div>
                <p>
                  If you are satisfied with the star rating achieved – apply for accreditation here.
                </p>
                <ul className="dashboard-list">
                  {!isCritical && percentageComplete === 100 && (
                    <li>
                      <JourneyLink to="/accreditation" disabled={disableAccreditation}>
                        Apply for accreditation
                      </JourneyLink>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <div>
                <div>
                  {reviewStatus === 'PEND' && (
                    <div>
                      <p>
                        Your application for accreditation, sent on{' '}
                        <span>{formatReviewDate(requestedDate)}</span>, has been marked as{' '}
                        <strong>{reviewStatus}</strong>.
                      </p>
                      {pendInfo?.map(({ ReviewerEmail, ActionedAt, ActionReason }, i) => (
                        <div key={i}>
                          <p>
                            <span>{ReviewerEmail}</span> set status to <strong>PEND</strong> on{' '}
                            <span>{formatReviewDate(ActionedAt)}</span>, commenting:
                            <br />"<span>{ActionReason}</span>"
                          </p>
                        </div>
                      ))}
                      <p>
                        You have until <span>{formatReviewDate(expiryDate)}</span> to resubmit this
                        application. If you choose not to, you will be able to start a new
                        application once this application expires.
                      </p>
                      <ul className="dashboard-list">
                        <li>
                          <JourneyLink to="/assessment" disabled={disableAccreditation}>
                            Update your assessment
                          </JourneyLink>
                        </li>
                        {!isCritical && (
                          <li>
                            <JourneyLink to="/accreditation" disabled={disableAccreditation}>
                              Reapply for accreditation
                            </JourneyLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  {reviewStatus === 'APPROVED' && (
                    <div>
                      <p>
                        Congratulations, your application status is <strong>{reviewStatus}</strong>.
                      </p>
                    </div>
                  )}
                  {reviewStatus === 'REJECTED' && (
                    <div>
                      <p>
                        Your application has been <strong>{reviewStatus}</strong>.
                      </p>
                      <hr />
                      <ul className="dashboard-list">
                        <li>
                          <JourneyLink to="/recommendations" disabled={disableAccreditation}>
                            View your advice and recommendations
                          </JourneyLink>
                        </li>
                        <li>
                          <JourneyLink to="/assessment" disabled={disableAccreditation}>
                            Review your assessment
                          </JourneyLink>
                        </li>
                      </ul>
                    </div>
                  )}
                  {reviewStatus === 'PENDING' && (
                    <div>
                      <p>
                        You applied for accreditation on{' '}
                        <span>{formatReviewDate(requestedDate)}</span>. Your application will be
                        under review until <span>{formatReviewDate(expiryDate)}</span>.
                      </p>
                      <p>
                        The current status of your application is: <strong>{reviewStatus}</strong>.
                      </p>
                      <p>
                        View your{' '}
                        <Link to="/accreditation">
                          reviewer's details and your supporting evidence
                        </Link>
                        .
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        <div className="foot"></div>
      </div>
    </>
  );
}

