import { DataColumn, parseData } from 'hooks/Reports/utils';
import React from 'react';
import { TableView } from './DataViews/TableView';
import { IReportConfig } from './IReportConfig';

interface IProps {
  reportName: string;
  reportConfig: IReportConfig;
  viewIndex?: number;
  data: string[][];
  resultColumns: DataColumn[];
}

enum DataViewType {
  Bar,
  Pie,
  Sunburst,
  Table,
}

export function ReportDataView(props: IProps) {
  const { reportName, reportConfig, viewIndex = 0, data, resultColumns } = props;

  const { pivot } = reportConfig;

  const parsedData = parseData(data, resultColumns, pivot);

  const view = reportConfig.views[viewIndex];
  const dataView = DataViewType[view.type as keyof typeof DataViewType];
  switch (dataView) {
    case DataViewType.Table: {
      return (
        <TableView
          viewName={view.title}
          downloadFilename={reportName}
          columns={parsedData.columns}
          data={parsedData.data}
          initialFilterOn={view.initialFilterOn}
        />
      );
    }
    default:
      throw new Error(`Unknown DataViewType: ${view.type}`);
  }
}
