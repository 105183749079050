import { getUrlPrefix } from './environment-utils';
import { Module } from './tillr-graphql';

export const TENANT_ID: number = 748;
export const MODULE_ID: Module = Module.Lite;
export const CLIENT_ID: string = 'lsavi.tillr.client';
export const DATE_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';
export const EXPIRY_FORMAT: string = 'days';
export const EXPIRY_LENGTH: number = 365;

// Forms
export const PREMISES_INFO_TEMPLATE_ID = 'c4817cd5-302c-4bce-afe1-c10deb796bd3';
export const PREMISES_INFO_FIRST_NAME_FIELD_ID: string = '8c8ee97b-145f-4758-ae9b-b62f1e3a4774';
export const PREMISES_INFO_LAST_NAME_FIELD_ID: string = '7d5898f9-111e-4d01-8682-898785e6e962';
export const PREMISES_INFO_PREMISES_NAME_FIELD_ID: string = '2c64012c-e51b-469f-a96e-26547f340648';
export const PREMISES_INFO_POSTCODE_FIELD_ID: string = 'ee08cc04-c721-4a9a-9843-93fa3019db61';
export const PREMISES_INFO_LAT_FIELD_ID: string = '5a8f05d7-04c3-4fa5-b560-2975ac850900';
export const PREMISES_INFO_LONG_FIELD_ID: string = '5a8f05d7-04c3-4fa5-b560-2975ac850901';
export const PREMISES_INFO_IS_CHAIN_FIELD_ID: string = 'b89652c2-5f5f-40a7-89de-d47c03332916';
export const PREMISES_INFO_CHAIN_NAME_FIELD_ID: string = '8a59c343-932e-46a0-81af-f8a2d9add030';

export const END_USER_AGREEMENT_TEMPLATE_ID = '4ddba79f-ee10-4f96-815c-159195f7355c';

export const ASSESSMENT_FORM_TEMPLATE_ID = '319CC685-B9EC-4BE1-912F-A7A1871033D4';
export const ASSESSMENT_TOTAL_FIELD_ID = 'c8ee1ba6-0169-4ffc-9dc0-3dd87214553a';
export const ASSESSMENT_REVIEWER_POLICE_AUTHORITY_FIELD_ID = 'ffffffff-ffff-ffff-ffff-fffffffffff0';
export const ASSESSMENT_REVIEWER_LOCAL_COUNCIL_FIELD_ID = 'ffffffff-ffff-ffff-ffff-fffffffffff1';

export const COMMENTS_FORM_ID = 'eeb8658f-bc33-4fdd-9532-24d412a4ba5f';

export const attachmentsFormIds = '0192ed49-2c9e-4ef6-a420-cc9181156362';

// Reports
export const VENUES_STATUS_REPORT_ID: string = 'D7B776E2-4CFB-455C-9B08-8FE0F51062A4';

export const RECOMMENDATIONS_REPORT_ID: string = '130b2338-8c9c-462c-9628-99111989d5a5';

export const ADMIN_REPORTS: Record<
  string,
  { name?: string; reportId: string; permissions: string[] }
> = {
  'activity-report': {
    reportId: '5419378e-ecff-49a0-91f3-9c962c8deb04',
    permissions: ['Admin'],
  },
  'approved-applications': {
    reportId: '66c72b02-2b28-4a71-8ca8-49bdb4e283c3',
    permissions: ['Admin'],
  },
  'accredited-venues-export': {
    reportId: '2CBBD9E3-4DAD-4F73-AE85-6C9D933B0DE5',
    permissions: ['Admin', 'Venues.Admin'],
  },
  'walksafe-export': {
    name: 'WalkSafe export',
    reportId: '2906B4D4-D285-44D5-BD20-54714AF8C593',
    permissions: ['Admin', 'Venues.Admin'],
  },
};

// Verifiers list library folder ID
export const VERIFIERS_FOLDER_ID: string = 'AF6514DA-372A-4872-8A94-3A33A148BD60';

export const VERIFIERS_FIELD_ID: string = '5daafbb8-8f1b-4cb4-9beb-c85f19201834';

// Stripe
export const STRIPE_PRODUCT_SKU =
  getUrlPrefix() === '' ? 'sku_JlDGAtOObb5gMF' : 'sku_JlDE8IugXGil0g';

export const STRIPE_PUBLISHABLE_KEY =
  getUrlPrefix() === ''
    ? 'pk_live_ME5pTzJHKkSpwWWNyAynyJet00lcE552pv'
    : 'pk_test_3of70HS74BmEV7X4G2i4sZB2003FFHzJ9S';

// Chains
export const CHAIN_MANAGER_ROLE_ID = '3b555530-4587-48b1-1af0-08dbfb2b6248';

export const STANDARD_USER_ROLE_ID = 'F3275E3A-6727-4D89-88D1-6B97D843D844';

export const premInfoIsChainElementId: string = 'b89652c2-5f5f-40a7-89de-d47c03332916';

export const premInfoChainNameElementId: string = '8a59c343-932e-46a0-81af-f8a2d9add030';

