import { SignOutButton } from 'auth/SignOutButton';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { ResourcesPanel } from 'components/Resources/ResourcesPanel';
import { TillrModal } from 'components/Shared/TillrModal';
import { UpdateMarketingPreferencesDialogContainer } from 'components/Shared/UpdateMarketingPreferencesDialogContainer';
import SiteContext from 'components/Sites/SiteContext';
import { useGetMyUserSettings } from 'hooks/useGetMyUserSettings';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useContext, useLayoutEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Accreditation } from './Accreditation';
import { Advice } from './Advice';
import { Assessment } from './Assessment';
import { Checklist } from './Checklist';
import { Complete } from './Complete';
import { Registered } from './Registered';
import { getJourneyState } from './utils';

export function JourneyPage() {
  const history = useHistory();

  const { siteId } = useContext(SiteContext)!;

  const { search } = useLocation();
  const paymentStatus = new URLSearchParams(search).get('payment');

  const { userSettings, isResponsibleAuthority } = useGetMyUserSettings();

  const getVenueStatusesState = useGetVenueStatuses(siteId);

  const journeyState = getJourneyState(
    getVenueStatusesState?.data?.filter((x) => x.SiteId === siteId),
    userSettings,
  );

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    handleScrollToTop();
  }, []);

  if (isResponsibleAuthority) {
    return <Redirect to="/" />;
  }

  if (getVenueStatusesState.error) {
    return (
      <>
        <div className="page">
          <section>
            <ErrorDisplay error="An error occurred, please refresh. If the error persists, try contacting support." />
          </section>
        </div>
      </>
    );
  }

  const handleClosePaymentSuccess = () => {
    if (journeyState.isPaymentRequired) {
      // Maybe we were too fast for the Reports service - refetch
      getVenueStatusesState.fetchData(siteId);
    }

    history.push('/journey');
  };

  return (
    <>
      {paymentStatus === 'success' && (
        <TillrModal title="Payment successful" onOk={handleClosePaymentSuccess}>
          {journeyState.isLoading ? (
            <ActivityIndicator />
          ) : (
            <>
              {journeyState.isRenewal ? (
                <>
                  <p>However, you're not done yet!</p>
                  <p>Please complete a new assessment so you can re-apply for accreditation.</p>
                </>
              ) : (
                <>
                  <p>Thank you for your payment.</p>
                  <p>You can now continue on your accreditation journey!</p>
                </>
              )}
            </>
          )}
        </TillrModal>
      )}

      <UpdateMarketingPreferencesDialogContainer />

      <div className="journey row">
        <div className="col-sm-8 col-xs-12">
          <section>
            <div className="wrapper" style={{ marginBottom: 0 }}>
              <h3>
                <span>{journeyState.title}</span> <SignOutButton />
              </h3>
            </div>

            <div className="wrapper">
              <div className="center-line">
                <a onClick={handleScrollToTop} className="scroll-icon">
                  <i className="glyphicon glyphicon-arrow-up"></i>
                </a>
              </div>

              <div className="steps step-1" id="step-1">
                <section>
                  <Registered />
                </section>
              </div>

              <div className="steps step-2" id="step-2">
                <section>
                  <i className="icon fas fa-home"></i>
                  <Checklist journeyState={journeyState} />
                </section>
              </div>

              <div
                className={`steps step-1 ${journeyState.disableAssessment ? 'disabled' : ''}`}
                id="step3"
              >
                <section>
                  <i className="icon fas fa-home"></i>
                  <Assessment journeyState={journeyState} />
                </section>
              </div>

              <div
                className={`steps step-2 ${journeyState.disableAdvice ? 'disabled' : ''}`}
                id="step-4"
              >
                <section>
                  <i className="icon fas fa-home"></i>
                  <Advice journeyState={journeyState} />
                </section>
              </div>

              <div
                className={`steps step-1 ${journeyState.disableAccreditation ? 'disabled' : ''}`}
                id="step-5"
              >
                <section>
                  <i className="icon fas fa-home"></i>
                  <Accreditation journeyState={journeyState} />
                </section>
              </div>

              <div
                className={`steps step-2 ${journeyState.disableComplete ? 'disabled' : ''}`}
                id="step-6"
              >
                <section>
                  <i className="icon fas fa-home"></i>
                  <Complete journeyState={journeyState} />
                </section>
              </div>
            </div>
          </section>
        </div>
        <div className="col-sm-4 col-xs-12">
          <section>
            <div className="wrapper">
              <h4 className="like-h3">Resources</h4>
              <ResourcesPanel />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

