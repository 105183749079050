import { Box, Card, CardContent, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeView from '@material-ui/lab/TreeView';
import { useGetSiteAndChildren, useGetUsersAdmin } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import LinkButton from 'components/Shared/LinkButton';
import { PageTitle } from 'components/Shared/PageTitle';
import { useGetVenueStatuses } from 'hooks/useGetVenueStatuses';
import React, { useContext, useEffect, useState } from 'react';
import { SiteItemFragment } from 'tillr-graphql';
import { CHAIN_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { useGetChainFromParams } from '../useGetChainFromParams';
import siteStyles from './siteStyles';
import { SiteTree } from './SiteTree';

export function ChainVenuesManagePage() {
  const getChainState = useGetChainFromParams();
  const { chainId, chainSite } = getChainState;

  const userProfile = useContext(UserProfileContext)!;
  const canManageChain = userProfile.hasAnyPermission(['Admin', 'Chain.Manage']);

  const classes = siteStyles();
  const getSitesState = useGetSiteAndChildren({ id: chainId });
  const getUsersState = useGetUsersAdmin({ siteId: chainId }, !canManageChain);
  const getVenueStatusesState = useGetVenueStatuses(chainId);

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    event.persist();
    const iconClicked = (event.target as Element).closest('.MuiTreeItem-iconContainer');
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  useEffect(() => {
    let expandedArray: string[] = [];
    if (getSitesState.data?.siteAndChildren) {
      expandedArray = getSitesState.data?.siteAndChildren.map((site) => site.id.toString());
    }
    setExpanded(expandedArray);
  }, [getSitesState.data, setExpanded]);

  const renderTree = (sites: SiteItemFragment[], parentSiteId: number | null) => {
    const childSites = sites
      .filter((x) => x.parentSiteId === parentSiteId)
      .sort((a, b) => a.name.localeCompare(b.name))
      // Put venues above groups
      .sort((a, b) => (a.type === b.type ? 0 : a.type === VENUE_SITE_TYPE ? -1 : 1));

    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <SiteTree
              key={x.id}
              chainId={chainId}
              siteId={x.id}
              getSitesState={getSitesState}
              getUsersState={getUsersState}
              getVenueStatusesState={getVenueStatusesState}
              name={x.name}
              siteType={(x.type ?? VENUE_SITE_TYPE) as SiteType}
              labelText={x.name}
              labelInfo="90"
            >
              {renderTree(sites, x.id)}
            </SiteTree>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <PageTitle title={chainSite?.name ?? 'Manage chain'} />
      <div className="page">
        <section>
          {canManageChain && (
            <Box display="flex" justifyContent="space-between">
              <Typography gutterBottom>
                Use the actions on the right to add groups, venues and users.
              </Typography>
              <Box mb={1}>
                <LinkButton to={`/chains/${chainId}/details`}>Details view</LinkButton>
              </Box>
            </Box>
          )}

          {(getSitesState.loading || getUsersState.loading) && (
            <ActivityIndicator showProgressBar />
          )}
          {getSitesState.error && <ErrorDisplay error={getSitesState.error} />}
          {getUsersState.error && <ErrorDisplay error={getUsersState.error} />}

          {getSitesState.data?.siteAndChildren &&
            chainSite &&
            (getUsersState.data || !canManageChain) && (
              <Card>
                <CardContent>
                  <TreeView
                    className={classes.root}
                    expanded={expanded}
                    defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
                    defaultExpandIcon={<ChevronRightIcon color="primary" />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    onNodeToggle={handleToggle}
                  >
                    <SiteTree
                      chainId={chainId}
                      siteId={chainId}
                      siteType={CHAIN_SITE_TYPE}
                      name={chainSite.name}
                      labelText={chainSite.name}
                      getSitesState={getSitesState}
                      getUsersState={getUsersState}
                      getVenueStatusesState={getVenueStatusesState}
                      labelInfo="90"
                    >
                      {renderTree(getSitesState.data.siteAndChildren, chainId)}
                    </SiteTree>
                  </TreeView>
                </CardContent>
              </Card>
            )}
        </section>
      </div>
    </>
  );
}
