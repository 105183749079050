import { ActivityIndicator } from 'components/ActivityIndicator';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnimatedCheck } from './AnimatedCheck';
import { CheckedIcon } from './CheckedIcon';
import { IJourneyState } from './utils';

interface IProps {
  journeyState: IJourneyState;
}

export function Checklist(props: IProps) {
  const { journeyState } = props;
  const { isLoading, isPremisesInfoComplete, isEndUserAgreementComplete } = journeyState;

  const isComplete = isPremisesInfoComplete && isEndUserAgreementComplete;

  return (
    <div className={isComplete ? 'success' : 'pending'}>
      {isLoading ? (
        <div style={{ position: 'absolute', top: 15, left: -60 }}>
          <ActivityIndicator size={40} />
        </div>
      ) : (
        <i className="icon">
          <i>2</i>
        </i>
      )}
      {isComplete && (
        <i className="icon">
          <AnimatedCheck />
        </i>
      )}

      {isLoading ? (
        <div className="mdl-spinner">
          <ActivityIndicator />
        </div>
      ) : (
        <>
          <div className="details">
            <h4>Starter Checklist</h4>
          </div>
          <p>Complete your Licensing SAVI premises information:</p>
          <ul>
            <li className="checklist">
              <CheckedIcon checked={isPremisesInfoComplete} />
              <Link to="/premises-info">Licensing SAVI premises information</Link>
            </li>
          </ul>
          <p>Read and agree to the end user agreement:</p>
          <ul>
            <li className="checklist">
              <CheckedIcon checked={isEndUserAgreementComplete} />
              <Link to="/end-user-agreement">End User Agreement</Link>
            </li>
          </ul>
          <div className="foot"></div>
        </>
      )}
    </div>
  );
}

